:root {
  --rpv-core__inner-page-background-color: #efefef;
}

.datepicker-calendar > div:nth-child(2) {
  @media (max-width: 728px) {
    display: none;
  }
}

.react-transform-wrapper,
.react-transform-component {
  width: auto !important;
}

.react-transform-component img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
}

svg {
  flex-shrink: 0;
}
